<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <FormulateForm name="change-password-form" class="change-password-form" v-model="values" @submit="changePasswordHandler">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">ALTERAR PALAVRA-PASSE</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="password" name="new-password" v-model="password" validation="required" placeholder="Insira a sua nova palavra-passe" label="Nova Palavra-Passe" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="password" name="confirm-password" v-model="repeatPassword" validation="required" placeholder="Confirme a sua nova palavra-passe" label="Confirmação Nova Palavra-passe" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
              <p>{{ formResponseMessage }}</p>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-change-password",
  components: {
    reservedAreaMenu,
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      password: '',
      repeatPassword: '',
      showMessage: false,
      formResponseMessage: '',
    };
  },
  methods: {
    changePasswordHandler() {
      let validatedPassword = '';
      if ( this.password === this.repeatPassword ) {
        validatedPassword = this.password;

        const requestBody = {
          updateType: 'password',
          password: validatedPassword,
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'customer', qs.stringify(requestBody))
            .then((response) => {
              console.log(response.data);
            })
            .catch((e) => {
              this.errors.push(e);
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            });
      }

    },
  },
  created() {
    this.$root.pageTitle = this.$root.translations.definicoes;
  }
}
</script>